<template>
  <div>
   <base-header class="pb-1">
       <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0"></h6>
        </div>
      </div>
    </base-header>

   <div class="container-fluid mt--6">
        <form>
            <card class="d-print-none">
                <h3 slot="header" class="mb-0">Relatório de Produtividade</h3>
                <div class="form-row mt-3">
                    <div class="col-md-6">
                        <base-input addon-left-icon="ni ni-calendar-grid-58" label="Data Inicio">
                            <flat-picker slot-scope="{focus, blur}"
                                        @on-open="focus"
                                        @on-close="blur"
                                        :config="{allowInput: true, dateFormat: 'd/m/Y', altFormat: 'M j, Y'}"
                                        class="form-control datepicker"
                                        placeholder="Data Inicial"
                                        v-model="filter.dateBegin">
                            </flat-picker>
                        </base-input>
                    </div>   
                    <div class="col-md-6">
                        <base-input addon-left-icon="ni ni-calendar-grid-58" label="Data Final">
                            <flat-picker slot-scope="{focus, blur}"
                                        @on-open="focus"
                                        @on-close="blur"
                                        :config="{allowInput: true, dateFormat: 'd/m/Y', altFormat: 'M j, Y'}"
                                        class="form-control datepicker"
                                        placeholder="Data Final"
                                        v-model="filter.dateEnd">
                            </flat-picker>
                        </base-input>
                    </div>
                </div>    
                <div class="col-md-12 text-right mb-3">
                    <base-button type="secundary" native-type="button" @click="printReport"><i class="fas fa-print mr-1"></i>Imprimir</base-button>
                    <base-button type="primary" native-type="button" @click="loadReport"><i class="fas fa-filter mr-1"></i> Filtrar</base-button>
                </div>
            </card>
            
            <div class="col-md-12">
                <card>
                    <h3 slot="header" class="mb-0">Filiais</h3>
                    <table class="table table-responsive font-tabela-peq">
                        <thead class="thead">
                            <tr>
                                <th scope="col">Filial</th>
                                <th scope="col">Quantidade de Assinaturas</th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="report in reportResponse.companies" :key="report.describe">
                                <td v-if="report.describe == null">INDEFINIDO</td>
                                <td v-if="report.describe != null">{{report.describe}}</td>
                                <td class="text-center">{{report.quantity}}</td>
                            </tr>
                        </tbody>
                    </table>
                </card> 

                <card>
                    <h3 slot="header" class="mb-0">Usuários</h3>
                    <table class="table table-responsive font-tabela-peq">
                        <thead class="thead">
                            <tr>
                                <th scope="col">Usuário</th>
                                <th scope="col">Quantidade de Assinaturas</th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="report in reportResponse.users" :key="report.describe">
                                <td v-if="report.describe == null">INDEFINIDO</td>
                                <td v-if="report.describe != null">{{report.describe}}</td>
                                <td class="text-center">{{report.quantity}}</td>
                            </tr>
                        </tbody>
                    </table>
                </card> 

                <card>
                    <h3 slot="header" class="mb-0">Corretores</h3>
                    <table class="table table-responsive font-tabela-peq">
                        <thead class="thead">
                            <tr>
                                <th scope="col">Corretor</th>
                                <th scope="col">Quantidade de Assinaturas</th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="report in reportResponse.brokers" :key="report.describe">
                                <td v-if="report.describe == null">INDEFINIDO</td>
                                <td v-if="report.describe != null">{{report.describe}}</td>
                                <td class="text-center">{{report.quantity}}</td>
                            </tr>
                        </tbody>
                    </table>
                </card> 

                <card>
                    <h3 slot="header" class="mb-0">Planos</h3>
                    <table class="table table-responsive font-tabela-peq">
                        <thead class="thead">
                            <tr>
                                <th scope="col">Plano</th>
                                <th scope="col">Quantidade de Assinaturas</th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="report in reportResponse.plans" :key="report.describe">
                                <td v-if="report.describe == null">INDEFINIDO</td>
                                <td v-if="report.describe != null">{{report.describe}}</td>
                                <td class="text-center">{{report.quantity}}</td>
                            </tr>
                        </tbody>
                    </table>
                </card> 
            </div>   
        </form>   
    </div> <!--- fecha conteudo -->
  </div>
</template>

<script>
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

export default {
   components: {
      flatPicker
  },
  mounted(){
      this.loadReport()
  },
  data() {
    return {
        reportResponse : [],
        filter:{
            dateBegin: null,
            dateEnd: null
        }
    }
  },
  methods: {
      printReport(){
          window.print()
      },
      loadReport(){
        this.$clubApi.post('/report/productivity', this.filter)
        .then((response) => {
            this.reportResponse = response.data.object
        }) .catch((error) => {
           this.$notify({type: 'warning', message: error.response.data.msg})
        }).finally(() =>{
            NProgress.done() 
        })
      }
  }
};
</script>